import { render } from "./PopupSettingForm.vue?vue&type=template&id=6ef12c06&scoped=true"
import script from "./PopupSettingForm.vue?vue&type=script&lang=ts"
export * from "./PopupSettingForm.vue?vue&type=script&lang=ts"

import "./PopupSettingForm.vue?vue&type=style&index=0&id=6ef12c06&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-6ef12c06"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QChip,QAvatar,QInput,QBtn,QFile,QIcon,QSpace});
